import React from 'react';
import { Link } from 'gatsby';
import shopImg from '../images/shop.jpg';
import SEO from '../components/seo';
import Layout from '../components/layout';

const AboutPage = ({location})  => {
  var serviceCity;
  if (location.state){
    serviceCity = location.state.serviceCity;
  }
  else {
    serviceCity = "Portland, OR";
  }
  return (
    <Layout serviceCity={serviceCity} currentPage='about' uniqueHeader={'About US and What We Do'}>
      <SEO title='About PhoneFix PRO' description={`PhoneFix Pro is a professional and trained mobile phone repair shop to solve all your mobile phone problems in Portland, OR.`}/>
      <div>
        <section className='section'>
          <div className='container'>
            <div className='aboutMainGrid'>
              <div className='aboutImageWrapper'>
                <img src={shopImg} className='aboutImg' alt="Shop"/>
              </div>
              <div className='aboutTextWrapper'>
                <p>
                  PhoneFix Pro is a professional and trained mobile phone repair
                  shop to solve all your mobile phone problems in Portland, OR.
                  We are a name that has maintained its reputation by making a
                  reliable bond with our customers. We understand that your
                  personal device of any sort can be highly valuable to you and
                  you are always cautious when it comes to depend on any company
                  for your device repairs. <br />
                  <br />
                  We assure you the best and responsible repair service for your
                  cell phones, iPhones, tablets, iPads, Samsung phone etc We
                  take pride in listing our services because they are the ones
                  highlighting the charm of our company. We have successfully
                  reached the place where we can provide any phone repair
                  service with surety and guarantee. You can also buy tablets
                  and phone accessories from our company. We offer you{' '}
                  <Link to='/cell-phone-repair' state={{ serviceCity }}>cell phone repair</Link>,
                  charging port repair, broken screen repair,{' '}
                  <Link to='/cell-phone-repair/iphone-repair' state={{ serviceCity }}>iPhone battery replacement</Link>,
                  iPhone water damage repair, iPhone touch camera repair,
                  tablets repair, logic board repair, smartphone unlocking,
                  iPhone camera repair, LG screen repair,{' '}
                  <Link to='/cell-phone-repair/samsung-repair' state={{ serviceCity }}>Samsung Galaxy screen repair</Link>
                  . <br />
                  <br />
                  We are confident that there is no other company to offer such
                  a wide range of repair options in Portland, Oregon. Get to
                  know more about our brilliant mobile phones and iPhone repair
                  in Portland, OR We know how hard it is to spend time or to
                  stay connected with the world while your phone or personal
                  device is not working properly. You are unable to use it and
                  it frustrates you. Get rid of all this troublesome situation
                  and hire us for a getting back in touch with the world.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};
export default AboutPage;
